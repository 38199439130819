import SyntaxHighlighter from 'react-syntax-highlighter'
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import createElement from 'react-syntax-highlighter/dist/esm/create-element'
import { useWindowSize } from '../../../../hooks/screenSize'

const codeString = `/*
  author: Donnell Reuben
  description: Work Experiences & Projects
*/

let workExperience = [
  {
    company: "DinkLabs",
    title: "Founder",
    time: "May 2024 - Present",
    location: "Chicago, IL",
    tasks: [
     "Developing app & website",
     "Closed beta platform for private pickleball lessons with Donnell Reuben",
     "Any DinkLab related inquiries please email dreuben@dinklabs.io"
    ]
  },
     {
    company: "Kyte",
    title: "Senior SDET",
    time: "Oct 2022 - Oct 2024",
    location: "Chicago, IL",
    tasks: []
     }
  },
  {
    company: "Tesla",
    title: "Software Engineer in Test"
    time: "Oct 2020 - Oct 2022",
    location: "Chicago, IL",
    tasks: []
  }
]

let projects = {
  work: [
    {
      name: "Tesla CRM",
      description: "A program used to keep track of the sales pipelines in realtime",
      website: "proprietary" (only viewable to tesla employees)
    },
    {
      name: "Kyte Surfer App | Website",
      description: "an app used by contract workers with kyte, built tests for maps api and website database",
      website: "https://kyte.com/",
      app: "https://web.fountain.com/kyte/apply/united-states-kyte-surfer" (only viewable after applying),
    }
  ],
  personal: [
    {
      name: "3D cube",
      description: "Just a simple 3D cube with custom controls"
      repository: "https://github.com/donnellreuben/Fullscreen-Resizing/tree/main"
    },

    {
      name: "Twitter clone",
      description: "a twitter clone with an animated sidebar made with rails",
      repository: "https://github.com/donnellreuben/Twitter-Clone"
    }
    {
      name: "Sway",
      description: "A song I made using Ableton, use the music controls to switch to it", 
      (hint: tap play/pause button once song is chosen on mobile)
      repository: "located on all music platforms"
    }
  ]
}
`

const WorkExperience = ({ padding = `10px` }) => {
  const windowSize = useWindowSize()

  // https://github.com/react-syntax-highlighter/react-syntax-highlighter/issues/104
  // Massive thanks to me, myself, and I
  const rowRenderer = ({ rows, stylesheet, useInlineStyles }) => {
    return rows.map((node, i) => {
      node.children = node.children.map((children) => {
        const text = children?.children?.[0]?.value

        if (typeof text === 'string' && text.startsWith('"http')) {
          return {
            ...children,
            tagName: 'a',
            properties: {
              ...children.properties,
              href: text.slice(1, -1), // in JSON strings are enclosed with ", they need to be removed,
              target: '_blank',
              style: { textDecoration: 'underline' }
            }
          }
        }

        return children
      })

      return createElement({
        node,
        stylesheet,
        useInlineStyles,
        key: `code-segement${i}`
      })
    })
  }

  return (
    <SyntaxHighlighter
      language="javascript"
      style={atomOneDark}
      renderer={rowRenderer}
      showLineNumbers={windowSize.width >= 1024}
      wrapLongLines
      customStyle={{ backgroundColor: 'rgba(40, 44, 52, 0.8)', padding }}
    >
      {codeString}
    </SyntaxHighlighter>
  )
}
export default WorkExperience
